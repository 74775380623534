import Cookies from "js-cookie";
import router from '@/router/index.js'

import http from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        user: "",
        userToken: "",
        permissions: []
    },
    mutations: {
        setUserData(state, payload) {
            state.permissions = payload.authorities
            state.user = payload.username
        },
        LOGIN(state, payload) {
            console.log("LOGIN")
            this.commit("auth/setUserData", payload.usuario)
            state.userToken = payload.token

            http.defaults.headers.common["Authorization"] = payload.token;


            Cookies.set("user", JSON.stringify(payload.usuario), { path: "", expires: 1 });
            Cookies.set("token", payload.token, { path: "", expires: 1 });

            router.push('/');
        },
        LOGOUT(state) {
            console.log("LOGOUT")
            state.user = "";
            state.userToken = "";
            state.permissions = [];

            Cookies.remove("user", { path: "" });
            Cookies.remove("token", { path: "" });

            router.push('/login');
        }
    },
    getters: {
        getPermissions: (state) => {
            let temp = {}

            state.permissions.forEach(element => {
                let [service, permission] = element.authority.split(':')
                if (permission) {
                    if (!temp[service]) temp[service] = []

                    temp[service].push(permission)
                }
            });

            return temp
        },
        getServices: (state, getters) => {
            return Object.keys(getters.getPermissions)
        }
    },
}