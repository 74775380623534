import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js'
import modules from './modules'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    grupos: [],
    usuarios: [],
    pessoas: [],
  },
  mutations: {
    add(state, { payload, type }) {
      state[type].push(payload)
    },
    edit(state, { payload, type }) {
      let index = state[type].findIndex((item => item.id == payload.id))
      state[type][index] = payload
    },
    delete(state, { payload, type }) {
      let index = state[type].findIndex((item => item.id == payload.id))
      state[type].splice(index, 1)
    },
  },
  actions: {
  },
  getters: {
    getItemById: (state) => (collection, id) => {
      let index = state[collection].findIndex((item => item.id == id))
      if (index > -1)
        return state[collection][index]
      else return {}
    }
  },
  modules: modules
})
