<template>
  <v-app-bar app elevation="0" dense color="primary" style="color: white">
    <v-app-bar-nav-icon
      color="white"
      @click="$store.commit('drawer/CHANGE_DRAWER')"
    ></v-app-bar-nav-icon>
    <v-toolbar-title class="pa-0 d-flex align-center justify-center">
      <span class="font-weight-bold" style="font-size: 30px"> dmi </span>
      <span class="ml-1" v-if="!isMobile">
        - Diagnóstico Municipal de
        <span class="font-weight-bold">Inclusão</span>
      </span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "appbar",
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    isMobile() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
    },
  },
  data: () => ({}),
};
</script>