import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

const BANDEIRA = {
  marrom: '#663300',
  azul: '#0000ff',
  verde: '#006600',
  amarelo: '#fff000'
}

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1371B4',
        // primary: BANDEIRA.azul,
        secondary: BANDEIRA.marrom,
        accent: BANDEIRA.azul,
        error: '#FF5252',
        info: '#2196F3',
        success: BANDEIRA.verde,
        warning: BANDEIRA.amarelo
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
});
