<template>
  <v-app style="background-color: #e6f4ff">
    <AppBar v-if="![null, 'Login'].includes($router.currentRoute.name)" />
    <Drawer v-if="![null, 'Login'].includes($router.currentRoute.name)" />

    <v-main>
      <router-view />
    </v-main>
    <notifications position="bottom left" />
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
import Drawer from "@/components/Drawer";
import Cookies from "js-cookie";

export default {
  name: "App",
  components: {
    AppBar,
    Drawer,
  },
  beforeCreate() {
    let user = Cookies.get("user");
    if (user) {
      this.$store.commit("auth/setUserData", JSON.parse(user));
    }
  },
  data: () => ({
    //
  }),
};
</script>

<style>
/* custom scrollbar */
::-webkit-scrollbar {
  width: 11px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>