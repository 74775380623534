import router from '@/router'
import store from '@/store'



const directive = {
    bind: function (el, binding, vnode) {
        if (!binding.value) return;

        const permissions = store.getters['auth/getPermissions']

        const currentService = router.currentRoute.path.substring(1)

        if (!permissions[currentService]) {
            vnode.elm.style.display = "none";
            return
        }

        if (!permissions[currentService].includes(binding.value))
            vnode.elm.style.display = "none";
    }
};

export default directive;
