<template>
  <v-navigation-drawer
    app
    dark
    v-model="$store.state.drawer.showDrawer"
    :width="isMobile ? '70%' : 180"
  >
    <v-list class="pt-0">
      <div v-for="(item, i) in routes" :key="i">
        <v-list-item
          style="height: 48px"
          v-if="item.to != '' ? services.includes(item.to) : true"
          :to="'/' + item.to"
          color="success"
        >
          <v-list-item-content>
            <v-list-item-title class="white--text font-weight-bold">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </div>
    </v-list>
    <template v-slot:prepend>
      <v-footer height="48" class="pa-1">
        <v-img contain height="40" src="@/assets/prefs.png" />
      </v-footer>
    </template>
    <template v-slot:append>
      <v-footer class="pa-0">
        <v-avatar class="mx-2" size="40" color="secondary">
          <v-icon size="30" color="white">mdi-account-circle</v-icon>
        </v-avatar>

        <v-list-item-content class="text-center">
          <v-list-item-title>{{ user }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-btn text x-small block @click="$store.commit('auth/LOGOUT')"
              >SAIR</v-btn
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-footer>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "appbar",
  computed: {
    ...mapGetters({ services: "auth/getServices" }),
    ...mapState({
      user: (state) => state.auth.user,
    }),
    isMobile() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
    },
  },
  data() {
    return {
      routes: [
        {
          icon: "mdi-home",
          title: "Início",
          to: "",
        },
        {
          icon: "mdi-account-box-multiple",
          title: "Grupos",
          to: "grupo",
        },
        {
          icon: "mdi-card-account-details",
          title: "Usuários",
          to: "usuario",
        },
        {
          icon: "mdi-account-multiple",
          title: "Cadastrados",
          to: "pessoa",
        },
      ],
    };
  },
};
</script>

