import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Cookies from "js-cookie";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/pessoa',
    name: 'Cadastrados',
    meta: { requiresAuth: true },
    component: () => import('../views/pessoas/pessoas.vue')
  },
  {
    path: '/grupo',
    name: 'Grupos',
    meta: { requiresAuth: true },
    component: () => import('../views/grupos/grupos.vue')
  },
  {
    path: '/usuario',
    name: 'Usuários',
    meta: { requiresAuth: true },
    component: () => import('../views/usuarios/usuarios.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const permissions = store.getters['auth/getServices']
  const containPermission = permissions.includes(to.path.substring(1))
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isLogged = Cookies.get('token') != undefined


  const destination = !requiresAuth || containPermission;

  // se a pagina não for encontrada 404
  if (!to.matched.length) next('/')

  if (to.path == '/login') {
    if (isLogged) {
      next('/') //Não vai pro login se estiver logado
      return
    }
    next();
    return
  }

  if (to.path == '/') {
    if (isLogged) {
      next()
      return
    }
    next('login');
    return
  }

  if (requiresAuth && !isLogged) {
    next('login');
    return
  }

  if (destination) next()
  else next('/')

});


export default router
