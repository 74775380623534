export default {
    namespaced: true,
    state: {
        showDrawer: window.innerWidth > 1264,
    },
    mutations: {
        CHANGE_DRAWER(state) {
            state.showDrawer = !state.showDrawer
        }
    },
}