import axios from "axios";
import Cookies from "js-cookie";
import store from '../store/index.js'

const http = axios.create({
    baseURL: 'https://dmi.parauapebas.pa.gov.br:8080', //servidor oficial
    // baseURL: 'http://192.168.0.107:8080', //local
});

http.defaults.headers.common["Authorization"] =
    Cookies.get("token");

http.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            console.log("autenticação expirada");
            store.commit('auth/LOGOUT')
            return
        }
        return Promise.reject(error)
    });

export default http